<script>
//Importing Bar class from the vue-chartjs wrapper
import {
    Bar
} from 'vue-chartjs'
//Exporting this so it can be used in other components
export default {
    extends: Bar,
    props: ['saTypeData'],
    data() {
        return {
            datacollection: {
                labels: ["개인상담", "가족", "커플", "부부", "소아청소년", "학습", "심리검사"],
                datasets: [{
                    label: "내담자수",
                    backgroundColor: "#2E66FA",
                    barPercentage: 0.75,
                    // barThickness: 15,
                    data: [20, 17, 18, 14, 23, 10, 25]
                }, {
                    label: "예약건수",
                    backgroundColor: "#F2660A",
                    barPercentage: 0.75,
                    // barThickness: 15,
                    data: [15, 14, 13, 13, 18, 9, 17]
                }, {
                    label: "상담건수",
                    backgroundColor: "#D3D3D3",
                    barPercentage: 0.75,
                    // barThickness: 15,
                    data: [10, 8, 7, 7, 12, 11, 4]
                },{
                    label: "초진횟수",
                    backgroundColor: "#ffcc00",
                    barPercentage: 0.75,
                    // barThickness: 15,
                    data: [5, 4, 4, 3, 9, 19, 23]
                }
                ,{
                    label: "취소",
                    backgroundColor: "#00bfff",
                    barPercentage: 0.75,
                    // barThickness: 15,
                    data: [2, 0, 0, 6, 4, 11, 5]
                }
                ]
            },
            //Chart.js options that controls the appearance of the chart
            options: {
                // 차트 생성시 애니메이션 효과 및 내부 차트 데이터 표시값 변경
                animation: {
                    onComplete: function () {
                        var ctx = this.chart.ctx;
                        ctx.textAlign = "center";
                        ctx.textBaseline = "top";
                        var chart = this;
                        var datasets = this.config.data.datasets;

                        datasets.forEach(function (dataset, i) {
                            ctx.font = "12px Lobster Two";
                            ctx.fillStyle = "#ffffff";
                            chart.getDatasetMeta(i).data.forEach(function (p, j) {
                                if (datasets[i].data[j] > 55) {
                                    ctx.fillText(datasets[i].data[j] + "%", p._model.x, p._model.y + 10);
                                }
                            });
                        });
                    }
                },
                tooltips: {
                    enabled: false
                },
                barValueSpacing: 20,
                scales: {
                    // y축 크기, 폰트 및 각종 옵션
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 5,
                            min: 0,
                            max: 25,
                            fontSize: 18,
                            fontFamily: 'Noto Sans KR',
                        },
                        gridLines: {
                            display: true
                        }
                    }],
                    // X축 크기, 폰트 및 각종 옵션
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        scaleLabel: {
                            display: true
                        },
                        ticks: {
                            fontSize: 14,
                            fontFamily: 'Noto Sans KR',
                        }
                    }]
                },
                legend: {
                    display: true,
                    position: 'bottom'
                },
                responsive: false, // 그래프 넓이 반응형 (true시 반응형, false시 height, width 커스텀 적용가능)
                maintainAspectRatio: true
            }
        }
    },
    mounted() {
        //renderChart function renders the chart with the datacollection and options object.
        this.renderChart(this.datacollection, this.options)
    }
}
</script>
