<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con mt-30">
                <div class="con_table">
                    <div class="search2">
                        <div class="float-left dp-block mb-10">
                            <button type="button" class="default-btn">오늘</button>
                            <button type="button" class="default-btn ml-10">금주</button>
                            <button type="button" class="default-btn ml-10">금월</button>
                        </div>

                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <label>기간검색 :
                            <crm-datepicker v-model="startDate" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="endDate" :parentDate="endDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <span class="font-14">월</span> <input type="checkbox" class="mr-10">
                            <span class="font-14">화</span> <input type="checkbox" class="mr-10">
                            <span class="font-14">수</span> <input type="checkbox" class="mr-10">
                            <span class="font-14">목</span> <input type="checkbox" class="mr-10">
                            <span class="font-14">금</span> <input type="checkbox" class="mr-10">
                            <span class="font-14">토</span> <input type="checkbox" class="mr-20">
                            <a class="btn_search">Search<span></span></a>
                        </label>
                    </div>
                     <div class="con_top mb-10 txt-center">
                        <h2>기간별 통계</h2>
                    </div>
                    <line-chart height="300" width="1250"></line-chart>
                    
                    <div class="dp-inblock w-600px">
                        <div class="con_top mb-10 txt-center">
                        <h2 class="mb-20">상담사 TOP5 Chart</h2>
                        <top-bar-chart height="300" width="550"></top-bar-chart>
                    </div>
                    </div>
                    <div class="dp-inblock w-600px">
                        <div class="con_top mb-10 txt-center">
                        <h2 class="mb-20">유형별 상담건수</h2>
                        <type-bar-chart class="ml-30" height="300" width="550"></type-bar-chart>
                    </div>
                    </div>
                    <div class="dp-inblock w-600px">
                        <div class="con_top mb-10 txt-center">
                        <h2 class="mb-20">주제별 상담건수</h2>
                        <topic-bar-chart height="300" width="550"></topic-bar-chart>
                    </div>
                    </div>
                    <div class="dp-inblock w-600px">
                        <div class="con_top mb-10 txt-center">
                        <h2 class="mb-20">성비별 상담건수</h2>
                        <gender-bar-chart class="ml-30" height="300" width="550"></gender-bar-chart>
                    </div>
                    </div>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li><a class="active pointer">1</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import LineChart from '@/components/utils/lineChart.vue'
import TopBarChart from '@/components/utils/topBarChart.vue'
import TypeBarChart from '@/components/utils/typeBarChart.vue'
import TopicBarChart from '@/components/utils/topicBarChart.vue'
import GenderBarChart from '@/components/utils/genderBarChart.vue'
export default {
     components: {
        LineChart,
        TopBarChart,
        TypeBarChart,
        TopicBarChart,
        GenderBarChart
    },
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜
    }),

    mounted() {

    },

    methods: {
    }
}
</script>
