<script>
//Importing Bar class from the vue-chartjs wrapper
import {
    Line
} from 'vue-chartjs'
//Exporting this so it can be used in other components
export default {
    extends: Line,
    data() {
        return {
            datas: ['R', 'I', 'A', 'S', 'E', 'C'],
            datacollection: {
                //Data to be represented on x-axis
                labels: ['8.20', '8.25', '9.1', '9.5', '9.10', '9.15'],
                datasets: [{
                        label: '고객수',
                        // barPercentage: 1,
                        // barThickness: 34,
                        // backgroundColor: ['#d5d5d5', '#d5d5d5', '#004f92', '#004f92', '#004f92', '#d5d5d5'],
                        // pointBackgroundColor: 'white',
                        fill: false,
                        borderWidth: 3,
                        borderColor: 'orange',
                        pointBorderColor: 'orange',
                        //Data to be represented on y-axis
                        data: [20, 51, 6, 9, 21, 32]
                    },
                    {
                        label: '예약수',
                        // barPercentage: 1,
                        // barThickness: 34,
                        // backgroundColor: ['#d5d5d5', '#d5d5d5', '#004f92', '#004f92', '#004f92', '#d5d5d5'],
                        // pointBackgroundColor: 'white',
                        fill: false,
                        borderWidth: 3,
                        borderColor: 'blue',
                        pointBorderColor: 'blue',
                        //Data to be represented on y-axis
                        data: [16, 17, 13, 10, 6, 3]
                    },
                    {
                        label: '상담진행수',
                        barPercentage: 1,
                        barThickness: 34,
                        // backgroundColor: ['#d5d5d5', '#d5d5d5', '#004f92', '#004f92', '#004f92', '#d5d5d5'],
                        // pointBackgroundColor: 'white',
                        fill: false,
                        borderWidth: 3,
                        borderColor: 'gray',
                        pointBorderColor: 'gray',
                        //Data to be represented on y-axis
                        data: [14, 12, 13, 14, 12, 11]
                    }
                ]
            },
            //Chart.js options that controls the appearance of the chart
            options: {
                // 차트 생성시 애니메이션 효과 및 내부 차트 데이터 표시값 변경
                animation: {
                    onComplete: function () {
                        var ctx = this.chart.ctx;
                        ctx.textAlign = "center";
                        ctx.textBaseline = "top";
                        var chart = this;
                        var datasets = this.config.data.datasets;

                        datasets.forEach(function (dataset, i) {
                            ctx.font = "12px Lobster Two";
                            ctx.fillStyle = "#ffffff";
                            chart.getDatasetMeta(i).data.forEach(function (p, j) {
                                if (datasets[i].data[j] > 55) {
                                    ctx.fillText(datasets[i].data[j] + "%", p._model.x, p._model.y + 10);
                                }
                            });
                        });
                    }
                },

                tooltips: {
                    enabled: false
                },
                scales: {
                    // y축 크기, 폰트 및 각종 옵션
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 10,
                            min: 0,
                            max: 60,
                            fontSize: 18,
                            fontFamily: 'Noto Sans KR',
                        },
                        gridLines: {
                            display: true
                        }
                    }],
                    // X축 크기, 폰트 및 각종 옵션
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        scaleLabel: {
                            display: true
                        },
                        ticks: {
                            fontSize: 18,
                            fontFamily: 'Noto Sans KR',
                        }
                    }]
                },
                legend: {
                    display: true,
                    position: 'bottom'
                },
                responsive: false, // 그래프 넓이 반응형 (true시 반응형, false시 height, width 커스텀 적용가능)
                maintainAspectRatio: true
            }
        }
    },
    mounted() {
        //renderChart function renders the chart with the datacollection and options object.
        this.renderChart(this.datacollection, this.options)
    }
}
</script>
