<script>
//Importing Bar class from the vue-chartjs wrapper
import {
    Bar
} from 'vue-chartjs'
//Exporting this so it can be used in other components
export default {
    extends: Bar,
    props: ['saTypeData'],
    data() {
        return {
            datacollection: {
                labels: ["상담사1", "상담사2", "상담사3", "상담사4", "상담사5"],
                datasets: [{
                    label: "고객수",
                    backgroundColor: "#2E66FA",
                    barPercentage: 0.75,
                    // barThickness: 15,
                    data: [20, 17, 18, 14, 23]
                }, {
                    label: "예약수",
                    backgroundColor: "#F2660A",
                    barPercentage: 0.75,
                    // barThickness: 15,
                    data: [15, 14, 13, 13, 18]
                }, {
                    label: "상담진행수",
                    backgroundColor: "#D3D3D3",
                    barPercentage: 0.75,
                    // barThickness: 15,
                    data: [10, 8, 7, 7, 12]
                },{
                    label: "초진횟수",
                    backgroundColor: "#ffcc00",
                    barPercentage: 0.75,
                    // barThickness: 15,
                    data: [5, 4, 4, 3, 9]
                }]
                //Data to be represented on x-axis
                // labels: ['상담사1', '상담사2', '상담사3', '상담사4', '상담사5'],
                // datasets: [{
                //         label: '고객수',
                //         barPercentage: 1,
                //         barThickness: 34,
                //         backgroundColor: ['#d5d5d5', '#d5d5d5', '#004f92', '#004f92', '#004f92', '#d5d5d5'],
                //         pointBackgroundColor: 'white',
                //         borderWidth: 1,
                //         pointBorderColor: '#249EBF',
                //         //Data to be represented on y-axis
                //         data: [20, 15, 10, 5]
                //     },
                //     {
                //         label: '예약수',
                //         barPercentage: 1,
                //         barThickness: 34,
                //         backgroundColor: ['#d5d5d5', '#d5d5d5', '#004f92', '#004f92', '#004f92', '#d5d5d5'],
                //         pointBackgroundColor: 'white',
                //         borderWidth: 1,
                //         pointBorderColor: '#249EBF',
                //         //Data to be represented on y-axis
                //         data: [19, 14, 9, 4]
                //     },
                //     {
                //         label: '상담진행수',
                //         barPercentage: 1,
                //         barThickness: 34,
                //         backgroundColor: ['#d5d5d5', '#d5d5d5', '#004f92', '#004f92', '#004f92', '#d5d5d5'],
                //         pointBackgroundColor: 'white',
                //         borderWidth: 1,
                //         pointBorderColor: '#249EBF',
                //         //Data to be represented on y-axis
                //         data: [18, 13, 8, 4]
                //     },
                //     {
                //         label: '초진횟수',
                //         barPercentage: 1,
                //         barThickness: 34,
                //         backgroundColor: ['#d5d5d5', '#d5d5d5', '#004f92', '#004f92', '#004f92', '#d5d5d5'],
                //         pointBackgroundColor: 'white',
                //         borderWidth: 1,
                //         pointBorderColor: '#249EBF',
                //         //Data to be represented on y-axis
                //         data: [17, 12, 7, 3]
                //     },
                // ]
            },
            //Chart.js options that controls the appearance of the chart
            options: {
                // 차트 생성시 애니메이션 효과 및 내부 차트 데이터 표시값 변경
                animation: {
                    onComplete: function () {
                        var ctx = this.chart.ctx;
                        ctx.textAlign = "center";
                        ctx.textBaseline = "top";
                        var chart = this;
                        var datasets = this.config.data.datasets;

                        datasets.forEach(function (dataset, i) {
                            ctx.font = "12px Lobster Two";
                            ctx.fillStyle = "#ffffff";
                            chart.getDatasetMeta(i).data.forEach(function (p, j) {
                                if (datasets[i].data[j] > 55) {
                                    ctx.fillText(datasets[i].data[j] + "%", p._model.x, p._model.y + 10);
                                }
                            });
                        });
                    }
                },
                tooltips: {
                    enabled: false
                },
                barValueSpacing: 20,
                scales: {
                    // y축 크기, 폰트 및 각종 옵션
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 5,
                            min: 0,
                            max: 25,
                            fontSize: 18,
                            fontFamily: 'Noto Sans KR',
                        },
                        gridLines: {
                            display: true
                        }
                    }],
                    // X축 크기, 폰트 및 각종 옵션
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        scaleLabel: {
                            display: true
                        },
                        ticks: {
                            fontSize: 18,
                            fontFamily: 'Noto Sans KR',
                        }
                    }]
                },
                legend: {
                    display: true,
                    position: 'bottom'
                },
                responsive: false, // 그래프 넓이 반응형 (true시 반응형, false시 height, width 커스텀 적용가능)
                maintainAspectRatio: true
            }
        }
    },
    mounted() {
        //renderChart function renders the chart with the datacollection and options object.
        this.renderChart(this.datacollection, this.options)
    }
}
</script>
